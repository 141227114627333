import { Cookies } from 'react-cookie';

const cookies = new Cookies();
const domain = process.env.GATSBY_COOKIES_DOMAIN;
const gatsbyEnv = process.env.GATSBY_ENV;
const isProduction = gatsbyEnv === 'production';

const shouldAddEnvPrefix = (name: string) => !isProduction && !name.startsWith('utm_');

const getEnvCookieName = (name: string) => (shouldAddEnvPrefix(name) ? `${gatsbyEnv}_${name}` : name);

export const getCookie = (name: string): string => {
  return cookies.get(getEnvCookieName(name));
};

export const doesCookieExist = (name: string): boolean => {
  return getCookie(name) !== undefined;
};

export const setCookie = (name: string, value: unknown, maxAge: number) => {
  if (typeof value === 'string' && value.trim().length === 0) {
    return;
  }

  removeCookie(name);
  const cookieName = getEnvCookieName(name);
  cookies.set(cookieName, value, { path: '/', maxAge: maxAge, domain });
};

export const removeCookie = (name: string) => {
  const cookieName = getEnvCookieName(name);
  cookies.remove(cookieName, { path: '/' });
  cookies.remove(cookieName, { path: '/', domain });
};
