export enum COOKIES {
  token = 'token',
  guestToken = 'guestToken',
  basketId = 'cart-id',
  currency = 'currency',
  couponCode = 'couponCode',
  vitlDiagnostics = 'vitl-diagnostics',
  userCountry = 'userCountry',
  essentialOnePartnership = 'essentialOnePartnership',
  personalisedPlusPartnership = 'personalisedPlusPartnership',
  personalisedPlus = 'personalisedPlus',
  utm_show_popup = 'utm_show_popup',
  banner = 'banner',
  referredTEOCoupon = 'referredTEOCoupon',
  trackingSessionId = 'tracking_session_id',
  personalizedPageType = 'personalizedPageType',
}

export enum COOKIE_EXPIRES_IN {
  halfAnHour = 30 * 60,
  anHour = 60 * 60,
  oneDay = 24 * 60 * 60,
  threeDays = 3 * 24 * 60 * 60,
  sevenDays = 7 * 24 * 60 * 60,
  fourteenDays = 14 * 24 * 60 * 60,
  thirtyDays = 30 * 24 * 60 * 60,
  oneYear = 365 * 24 * 60 * 60,
  maxAge = 1000 * 60 * 60 * 24 * 14,
}
