export enum OFFER_CODES {
  EssentialOne = 'EO',
  PersonalisedPlus = 'PP',
}

export enum PACK_NAMES {
  EO = 'Essential One',
  PP = 'Personalised Pack',
}

export enum GRAPH_QL_ERROR_TYPES {
  InvalidToken = 'InvalidToken',
  InvalidGuestId = 'InvalidGuestId',
  Unauthorized = 'Unauthorized',
  InvalidPassword = 'InvalidPassword',
  EmailExists = 'EmailExists',
  EmailNotFound = 'EmailNotFound',
  invalidCoupon = 'invalidCoupon',
  InvalidCode = 'InvalidCode',
  invalidPaymentMethod = 'invalidPaymentMethod',
  invalidBarcode = 'invalidBarcode',
  underAge = 'underAge',
  invalidBiologicalSex = 'invalidBiologicalSex',
  userKitLimitReached = 'userKitLimitReached',
  RegionRestricted = 'RegionRestricted',
  ProductUnavailable = 'ProductUnavailable',
  CartProductLimitMet = 'CartProductLimitMet',
  CartAlreadyOrdered = 'CartAlreadyOrdered',
  CartError = 'CartError',
  CouponRequiresAccount = 'CouponRequiresAccount',
  InvalidCoupon = 'InvalidCoupon',
  CouponRequiresHealthProfile = 'CouponRequiresHealthProfile',
  CouponRequiresPersonalisedRecommendation = 'CouponRequiresPersonalisedRecommendation',
  CouponValidFirstOrderOnly = 'CouponValidFirstOrderOnly',
  CouponValidFirstPersonalisedOrderOnly = 'CouponValidFirstPersonalisedOrderOnly',
  CannotReferOneself = 'CannotReferOneself',
  CouponAlreadyRedeemed = 'CouponAlreadyRedeemed',
  CouponExpired = 'CouponExpired',
  IncorrectCurrency = 'IncorrectCurrency',
  EmptyCart = 'EmptyCart',
  CartPaymentMethodMissing = 'CartPaymentMethodMissing',
  CartShippingAddressMissing = 'CartShippingAddressMissing',
  MultipleBillingFrequenciesNotSupported = 'MultipleBillingFrequenciesNotSupported',
  DefaultPaymentMethodNotRemovable = 'DefaultPaymentMethodNotRemovable',
  NoUserDetailsFound = 'NoUserDetailsFound',
  LinkExpired = 'LinkExpired',
  MultipleSubscriptionCurrenciesNotSupported = 'MultipleSubscriptionCurrenciesNotSupported',
  PaymentRequiresSCA = 'PaymentRequiresSCA',
  HealthProfileGenerationFailed = 'HealthProfileGenerationFailed',
}

export enum TRUST_PILOT_WIDGET_TYPES {
  MicroStar = 'MicroStar',
  Carousel = 'Carousel',
  MicroCombo = 'MicroCombo',
}

export enum CUSTOM_TRACKING_EVENTS {
  BasketUpsellClick = 'basket_subscriptionupsell_clicked',
  BasketUpsellView = 'basket_subscriptionupsell_viewed',
}

export enum OFFER_TYPES {
  FreeTrial = 'free-trial',
  Percent = 'percent',
  SpecialPrice = 'special-price',
  Fixed = 'fixed',
  CartFixed = 'cart_fixed',
}

export enum OFFER_DURATION_TYPES {
  Once = 'once',
  Forever = 'forever',
  Repeating = 'repeating',
}
