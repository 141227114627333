import { ApolloError } from 'apollo-boost';
import { CURRENCY_MAP } from '../constants';
import { getCurrency } from '../utils/currency';

export const formatPrice = (price: number, priceCurrency?: string, minimumFractionDigits = 2) => {
  const currency = priceCurrency || getCurrency();

  if (!currency || price == null) {
    return '';
  }

  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency,
    minimumFractionDigits,
  }).format(price);
};

export const formatCurrency = (currencyCode: string) => {
  const currencyItem = CURRENCY_MAP.find(
    currencyItem => currencyItem.currencyCode === currencyCode
  );
  return currencyItem?.currencySymbol;
};

export const formatDate = (date: any) => {
  const parsedDate =
    (date && date.toString().includes('-')) || date.toString().includes(':')
      ? Date.parse(date)
      : new Date(date * 1000);

  if (parsedDate) {
    return new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }).format(parsedDate);
  }
};

export const formatUnixDate = (date: any) => new Date(date * 1000);

export const formatExpiryDate = (month: number, year: number) => {
  const formattedMonth = month.toString().length === 1 ? `0${month}` : month;
  const formattedYear = year.toString().substr(year.toString().length - 2);

  return `${formattedMonth}/${formattedYear}`;
};

export const formatGraphQLError = (error: ApolloError | string) => {
  const gatsbyEnv = process.env.GATSBY_ENV;
  const isProduction = gatsbyEnv === 'production';

  if (typeof error == 'boolean') return null;
  if (!isProduction) return error.toString();
  return error.toString().replace('Error: GraphQL error: ', '').replace('GraphQL error: ', '');
};

export const formatPhoneErrorMessage = ({ type }: { type: string }) => {
  if (type === 'required') return 'Please enter your phone number';
  else if (type === 'validate') return 'Please enter a valid phone number';
};

export const formatStringToBoolean = (variable: string | undefined) =>
  variable?.toLowerCase() === 'true';

export const formatStripePaymentMethodStatus = (status: string) => {
  switch (status) {
    case 'succeeded':
      return 'Success! Your payment method has been saved.';
    case 'processing':
      return "Processing payment details. We'll update you when processing is complete.";
    case 'requires_payment_method':
      return 'Failed to process payment details. Please try another payment method.';
  }
};

export const snakeToCamel = (str: string) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''));

export const isStringValueValidNumber = (value: any) => {
  const val = value && value.replace('>', '').replace(',', '');
  return !isNaN(parseFloat(val)) && isFinite(val);
};
