import { OFFER_CODES } from './enums';

export const PRODUCT_COOKIES = {
  ESSENTIAL_ONE: {
    name: 'essentialOnePartnership',
    value: {
      FREE_TRIAL: 'essential-one-free-trial',
      REFERRAL: 'essential-one-referral',
      TRIAL: 'essential-one-price-test',
      ESSENTIAL_ONE_20_OFF_FREE_TRIAL: 'essential-one-trial-20off',
      AFFILIATES: 'essential-one-affiliates',
    },
  },
  PERSONALISED_PLUS: {
    name: 'personalisedPlusPartnership',
    value: {
      FREE_TRIAL: 'free-trial',
      FB: 'FB2week-trial',
      GA: 'GA2week-trial',
      TIKTOK: 'TikTok2week-trial',
      FIFTY_PERCENT_OFF: '50-off-personalised-plus',
      FIFTY_FIVE_PERCENT_OFF: '55-off-personalised-plus',
      THIRTY_OFF_TREE_MONTH: '30-off-3months',
      SEVENTY_OFF: '70-off-personalised',
    },
  },
};

export const PAGE_URL_TO_PRODUCT_CODE: {
  [pageName: string]: {
    productCode: string;
  };
} = {
  'essential-one': {
    productCode: OFFER_CODES.EssentialOne,
  },
  personalised: {
    productCode: OFFER_CODES.PersonalisedPlus,
  },
};

export const COOKIE_DATA: {
  [cookieValue: string]: {
    couponCode: string;
  };
} = {
  'essential-one-price-test': {
    couponCode: 'TE1PT',
  },
  'essential-one-trial-20off': {
    couponCode: 'TE1195',
  },
  'free-trial': {
    couponCode: 'VITS2020FT',
  },
  'FB2week-trial': {
    couponCode: 'FBFT',
  },
  'GA2week-trial': {
    couponCode: 'GAFT',
  },
  'TikTok2week-trial': {
    couponCode: 'TTFT',
  },
  '30-off-3': {
    couponCode: '30-off-3months',
  },
  '50-off-personalised-one-month': {
    couponCode: '50-off-personalised',
  },
  '55-off-personalised-one-month': {
    couponCode: '55-off-personalised',
  },
  '70-off-personalised-one-month': {
    couponCode: '70-off-personalised',
  },
};

export const CURRENCY_MAP = [
  {
    currencyCode: 'GBP',
    currencySymbol: '£',
  },
  {
    currencyCode: 'EUR',
    currencySymbol: '€',
  },
  {
    currencyCode: 'USD',
    currencySymbol: '$',
  },
  {
    currencyCode: 'AUD',
    currencySymbol: '$',
  },
];

export const AWIN_URL = `https://www.dwin1.com/${process.env.GATSBY_AWIN_ADVERTISER_ID}.js`;
