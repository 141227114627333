import * as Sentry from '@sentry/gatsby';
import { formatStringToBoolean } from './src/services/format';

const isSentryEnabled = formatStringToBoolean(process.env.GATSBY_SENTRY_ENABLED);

Sentry.init({
  beforeSend: event => {
    if (isSentryEnabled) return event;
    return null;
  },
  dsn: `${process.env.GATSBY_SENTRY_DSN}`,
  sampleRate: 0.75,
  attachStacktrace: true,
  environment: `${process.env.GATSBY_ENV}`,
});

Sentry.configureScope(scope => {
  scope.setTransactionName(`Shopfront`);
});
