export const ERROR_MESSAGES: { [key: string]: string } = {
  generic: 'Please try again later',
  updateEmailSubscription:
    'There was a problem updating your communication preferences. Please try again later.',
  updateUserDetails: 'There was a problem updating your user details. Please try again later.',
  updateUserEmail: 'There was a problem updating your email. Please try again later.',
  getEssentialOneProduct:
    'There was a problem fetching your Essential One Product. Please try again later.',
  getPersonalisedPackProduct:
    'There was a problem fetching your Personalised Pack. Please try again later.',
  getPaymentMethods:
    'We are unable to display your payment methods at the moment. Please try again later.',
  updatePaymentMethod: 'We are unable to update your payment method. Please try again later.',
  removePaymentMethod: 'We are unable to remove your payment method. Please try again later.',
  userSavePaymentMethod: 'We are unable to save your payment method. Please try again later.',
  cancelSubscription: "We couldn't cancel your subscription. Please try again later",
  pauseSubscription: "We couldn't pause your subscription. Please try again later",
  unpauseSubscription: "We couldn't restart your subscription. Please try again later",
  updateSubscriptionAddress:
    "We couldn't update your subscription's address. Please try again later",
  createPaymentSetupIntent: "We couldn't add your payment. Please try again later",
  addressLookup: 'There was a problem with looking up your address. Please try again later',
  removeItemFromCart:
    'There was a problem removing your item from the basket. Please try again later.',
  setItemQuantity: "There was a problem updating your item's quantity. Please try again later.",
  removeCoupon: 'There was a problem removing your coupon. Please try again later.',
  addCoupon: 'Ooops! You’re one step ahead. Please add products to your basket first',
  selectGoals: 'There was a problem updating your goals. Please try again later.',
  addItemToBasket: "We couldn't add your item to the basket. Please try again later",
  kitCanRegister: "We couldn't register your kit. Please try again later",
  registerUser: 'We were not able to register you. Please try again later',
  getRecommendedProducts: "We couldn't get your recommended products. Please try again later",
  getProductPlans: "We couldn't get your product plans. Please try again later",
  getOrder: 'We are unable to load your order. Please try again later.',
  updatePhoneNumber: 'There was a problem updating your phone number. Please try again later.',
  getListOfCountries:
    'There was a problem fetching a list of delivery countries. Please try again later.',
  createCartSession: 'There was a problem proceeding with your checkout. Please try again later.',
  setShippingAddress: 'There was a problem setting your delivery address. Please try again later.',
  addShippingAddress:
    'There was a problem adding your new delivery address. Please try again later.',
  orderCart: 'There was a problem placing your order. Please try again later.',
  emailExists:
    'It looks like you already have an account with that email address, please login and try again.',
  missingShippingAddress: 'Shipping address missing',
  freeTrialOfferLimitReached50PercentRedirect: 'Availability for our free trial offer has now reached its limit, we\'re sorry you missed out! \n\nYou can still enjoy 50% off your first month. Check this page for more details.',
  freeTrialOfferLimitReached70PercentRedirect: 'Availability for our free trial offer has now reached its limit, we\'re sorry you missed out! \n\nYou can still enjoy 70% off your first month. Check this page for more details.',
  invalidToken: 'Your link is invalid or has expired. Please try again.',
  invalidGuestIdForPromotion: 'Your previous session was not found and could not be added to your account.',
  healthProfileGenerationFailed: 'We are unable to generate your recommendations. Please try again.',
};
