export const LANA_AI_EXAMPLE_QUERIES = {
  weight: [
    "How can I manage my weight?",
    "What foods help with weight management?",
    "How much should I eat to maintain a balanced weight?",
    "Do I need to avoid certain foods?",
    "What's a healthy portion size?",
    "Are there low-calorie snacks?",
    "How can I control cravings?",
    "What's a good exercise for weight balance?",
    "Should I track my calories?",
    "How often should I weigh myself?"
  ],
  energy: [
    "What foods support energy levels?",
    "Best vitamins for feeling energised?",
    "How much sleep do I need?",
    "Foods to avoid for energy?",
    "Exercises to increase energy?",
    "Snacks for a quick energy boost?",
    "How to maintain energy all day?",
    "Energy-boosting breakfast ideas?",
    "Best lunch for afternoon energy?",
    "Does water intake affect energy?"
  ],
  stress: [
    "How can I manage stress better?",
    "Which foods reduce anxiety?",
    "Can exercise help with stress?",
    "How much sleep do I need?",
    "What vitamins support relaxation?",
    "Are there stress-reducing activities?",
    "How can I practise mindfulness?",
    "Can diet affect my stress levels?",
    "What are calming teas?",
    "How to balance work and relaxation?"
  ],
  digestion: [
    "How can I improve my digestion?",
    "What foods support healthy digestion?",
    "How much fibre should I eat?",
    "Are there foods to avoid for digestion?",
    "How much water helps with digestion?",
    "Do probiotics help with digestion?",
    "How often should I eat for good digestion?",
    "What snacks support healthy digestion?",
    "Can exercise help my digestion?",
    "Which vitamins support digestive health?"
  ],
  skin: [
    "How much water should I drink?",
    "Which foods help my skin?",
    "What vitamins support skin health?",
    "Should I avoid sugar for better skin?",
    "Does sleep affect skin health?",
    "Best skincare routine for glowing skin?",
    "Do oils benefit my skin?",
    "How does stress affect my skin?",
    "Are probiotics good for my skin?",
    "Can exercise improve my skin?"
  ],
  immunity: [
    "How can I support my immunity?",
    "What foods help with immunity?",
    "How much sleep supports immunity?",
    "Does exercise help immunity?",
    "Which vitamins help immunity?",
    "Can stress affect immunity?",
    "Are probiotics good for immunity?",
    "Is hydration important for immunity?",
    "How does diet affect immunity?",
    "Can supplements support immunity?"
  ],
  sleep: [
    "How can I sleep better?",
    "What can help me fall asleep faster?",
    "What's good for a restful night's sleep?",
    "Are there foods that support sleep?",
    "Should I avoid screens before bed?",
    "How much sleep is ideal?",
    "Can exercise support better sleep?",
    "Is my bedroom too warm for sleep?",
    "Are naps good for my sleep routine?",
    "How can I relax before bed?"
  ],
  fertility: [
    "What foods support fertility?",
    "Can vitamins aid fertility?",
    "Is exercise important for fertility?",
    "How does stress affect fertility?",
    "What is a fertility-friendly diet?",
    "Can hydration impact fertility?",
    "Does sleep influence fertility?",
    "Are there herbs for fertility?",
    "Should I avoid certain foods?",
    "How much protein for fertility?"
  ],
  hair: [
    "What foods support healthy hair?",
    "Do vitamins help hair growth?",
    "Is protein important for hair health?",
    "How often should I wash my hair?",
    "Can stress affect my hair?",
    "What oils are good for hair?",
    "Does hydration affect my hair?",
    "How can I reduce hair damage?",
    "What supplements support hair health?",
    "How can I prevent hair breakage?"
  ],
  detox: [
    "What foods help detox?",
    "How can I detox naturally?",
    "Are detox teas effective?",
    "Should I try a detox diet?",
    "Does lemon water detox?",
    "Best fruits for detox?",
    "Can exercise aid detox?",
    "Is fasting good for detox?",
    "Benefits of detox smoothies?",
    "How does sleep support detox?"
  ],
  fitness: [
    "What should athletes eat before training?",
    "How much protein for muscle growth?",
    "What's a good post-workout snack?",
    "How much water should I drink daily?",
    "How can I improve endurance?",
    "What are quick healthy breakfast ideas?",
    "How can I avoid muscle cramps?",
    "What are the best recovery foods?",
    "How many rest days a week?",
    "Which vitamins support energy?"
  ],
  brain: [
    "What foods support cognitive health?",
    "How can I improve memory?",
    "Recommend brain-boosting foods.",
    "How does hydration affect the brain?",
    "What vitamins support cognition?",
    "How to reduce brain fog?",
    "Best exercises for brain health?",
    "What reduces cognitive decline?",
    "How much sleep supports cognition?",
    "How to manage stress for the brain?"
  ],
  bones: [
    "How can I support my bone health?",
    "What foods help with strong bones?",
    "How much calcium do I need?",
    "What are some calcium-rich snacks?",
    "Do I need vitamin D for strong bones?",
    "What exercises strengthen bones?",
    "How often should I exercise for bone health?",
    "Are there any bone-friendly vitamins?",
    "Can dairy improve my bone strength?",
    "Should I take supplements for my bones?"
  ],
  joints: [
    "How can I support my joints?",
    "Which foods are good for joint health?",
    "How much calcium do I need daily?",
    "What exercises help joint flexibility?",
    "Is fish oil good for joints?",
    "What supplements support joint health?",
    "How does weight affect my joints?",
    "What are signs of joint issues?",
    "Can diet reduce joint pain?",
    "How important is vitamin D for joints?"
  ]
};